import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/cms-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Foxtrot Team`}</h1>
    <p>{`Foxtrot Aviation Services is an aviation detailing business focused on world class results while maintaining our midwestern values. Headquartered in Canton, Ohio we currently have eight locations throughout the state with mobile services available upon request.`}</p>
    <p>{`Our highly trained and insured staff includes over twenty professional and certified detailers who have the experience needed to give you world class results.`}</p>
    <p>{`FoxTrot offers services 365 days a year, 24 hours a day, to better serve our clients. Contact us today to experience the FoxTrot difference!`}</p>
    <h2>{`Our Mission`}</h2>
    <p>{`To safely and efficiently maintain aircraft and facility appearance, enhance client’s image, and help elevate the passenger experience to the highest possible standard while connecting airport communities through our family values and friendly, professional staff.`}</p>
    <h2>{`Our Values`}</h2>
    <p><strong parentName="p">{`Perseverance.`}</strong>{` FoxTrot is rooted in overxoming obstacles to success, whether they be personal barriers or professional.`}</p>
    <p><strong parentName="p">{`Improvement.`}</strong>{` FoxTrot is committed to finding new ways to complete services more safely and efficiently, resulting in higher quality results at competitive prices.`}</p>
    <p><strong parentName="p">{`Growth.`}</strong>{` FoxTrot is focused on expanding the business by developing a reputation as a world class aviation support services provider.`}</p>
    <p><strong parentName="p">{`Family.`}</strong>{` FoxTrot welcomes all of the aviation community and supports its employees to promote a sustainable, positive culture.`}</p>
    <p><strong parentName="p">{`Passion for Results.`}</strong>{` FoxTrot employees strive to achieve the best customer satisfaction.`}</p>
    <p><strong parentName="p">{`Accountability.`}</strong>{` If there are mistakes, FoxTrot employees own up to any incident and work with the client to resolve the problem and make it a learning ecperience for all parties involved.`}</p>
    <p><strong parentName="p">{`Client Focus.`}</strong>{` FoxTrot caters to every need and want a client might have and develops meaningful and lasting relationships.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      